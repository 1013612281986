import type {
  ResearchType,
  FirmType,
  ResearchContactInformationType,
  ResearchLocationType,
} from '@/types/ResearchType';
import { orderBy } from 'lodash';

export class Research {
  readonly _id: string;
  readonly _potential_demand: number;
  readonly _domain: string;
  readonly _name: string;
  readonly _place_id: string;
  readonly _contact_information: ResearchContactInformationType;
  readonly _firms: FirmType[];
  readonly _practice_area: string;
  readonly _practice_area_core_keyword?: string;
  readonly _location: string;

  constructor(data: ResearchType) {
    this._id = data._id;
    this._potential_demand = data.potential_demand;
    this._domain = data.domain;
    this._name = data.name;
    this._location = data.location.name;
    this._place_id = data.place_id;
    this._firms = this.getRelevantFirms(data.objects);
    this._contact_information = data.contact_information;
    this._practice_area = data.practice_area;
    this._practice_area_core_keyword = data.practice_area_core_keyword;
  }

  public getUserFirm(): FirmType {
    const userFirm = this._firms.find((item) => item.type == 'user');

    if (userFirm) return userFirm;

    throw new Error("Type user doesn't exist");
  }

  public getCompetitors(): FirmType[] {
    const competitors = this._firms.filter((item) => item.type == 'competitor');

    if (competitors && competitors.length) return competitors;

    throw new Error("Type competitor doesn't exist");
  }

  public getRelevantFirms(firms: FirmType[]): FirmType[] {
    const filterByVisitors = firms.filter(
      (item) => item.web_presence.total_traffic > 0,
    );

    const sortByTotalScore = orderBy(
      filterByVisitors,
      (firm) => firm.total_score,
      ['desc'],
    );

    const result = [];

    // First top 4 competitors
    let i = 0;
    for (const value of sortByTotalScore) {
      if (i == 4) {
        break;
      }

      if (value.type === 'competitor') {
        result.push(value);
        i++;
      }
    }

    // User firm
    const userFirm = firms.find((item) => item.type == 'user');

    if (userFirm) {
      result.push(userFirm);
    }

    return result;
  }

  public isAuthority(): boolean {
    return Boolean(this.getUserFirm()?.serpstat_summary);
  }
}
